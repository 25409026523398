<template>
  <Toast />

  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Reportes</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Ventas a Credito</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Pagos Interes</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h4><strong>REPORTE VENTAS A CREDITO - INTERESES</strong></h4>
    </div>
    <div class="col-12">
      <div class="card p-fondo">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-3">
            <label for="sucursal"><strong>SUCURSAL: </strong></label>
            <Dropdown
              v-model="sucursalSelected"
              :options="sucursales"
              optionLabel="nombre"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-2">
            <label for="id_caja"><strong>COD. CLIENTE: </strong></label>
            <InputNumber
              v-model="codigo_cliente"
              placeholder="123"
              :inputClass="'text-right'"
              :min="0"
              @keyup.enter="GetClienteCodigo"
            />
          </div>
          <div class="field col-12 md:col-5">
            <label
              for="id_caja"
              :class="errorClienteSelected ? 'p-invalid' : ''"
              ><strong>NOMBRE CLIENTE: </strong></label
            >
            <Dropdown
              v-model="clienteSelected"
              ref="clientes_select"
              optionLabel="nombre"
              placeholder="Nombre Cliente"
              editable
              :loading="enviando"
              :options="clientes"
              :inputClass="'text-right'"
              :class="errorClienteSelected ? 'p-invalid' : ''"
              @keyup.enter="BuscarClientePorNombre"
            />
          </div>

          <div class="field col-12 md:col-2">
            <label for=""><strong>ESTADO VENTAS: </strong></label>
            <Dropdown
              v-model="estadoVentasSelected"
              :options="estadosVentas"
              optionLabel="nombre"
              optionValue="id"
            >
            </Dropdown>
          </div>
        </div>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-3">
            <label for="usuarios"><strong>MES: </strong></label>
            <Dropdown
              v-model="mesSelected"
              :options="meses"
              optionLabel="name"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-3">
            <label for="fecha_apertura_desde"><strong>FECHAS: </strong></label>
            <Calendar
              v-model="rango_fecha_busqueda"
              selectionMode="range"
              dateFormat="dd/mm/yy"
            />
          </div>

          <div class="field col-12 md:col-3">
            <label for="fecha_vencimiento_hasta"
              ><strong>ACCIONES: </strong></label
            >
            <div class="flex">
              <Button
                label="APLICAR FILTROS"
                icon="pi pi-search"
                class="p-button-primary mr-2"
                :loading="enviando"
                :disabled="enviando"
                @click="buscarDatos"
              />
              <Button
                icon="pi pi-refresh"
                class="p-button-secondary mr-2 p-button-outlined p-button-sm"
                :loading="enviando"
                :disabled="enviando"
                v-tooltip="'Limpiar Filtros'"
                @click="LimpiarFiltro"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        class="p-datatable-sm"
        ref="reporte_ventas_credito_interes"
        responsiveLayout="scroll"
        responsive="true"
        stripedRows
        showGridlines
        :value="ventas"
        :filters="filters"
        @row-dblclick="verDetalleVenta"
        selectionMode="single"
        :loading="enviando"
      >
        <!--  <template #header v-if="ventas.length > 0">
          <div class="
              flex flex-column
              md:flex-row md:justify-content-end md:align-items-end
            ">
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <Button label="PDF" type="button"
                icon="pi pi-file-pdf" class="p-button-outlined p-button-danger" v-tooltip.top="'Exportar PDF Ventas i'"
                :loading="generando_pdf" :disabled="generando_pdf" @click="generarPDF()" />
            </span>
          </div>
        </template> -->
        <Column field="id" header="ID" :sortable="true" :filter="true" />
        <Column field="nombre_cajero" header="CAJERO" />
        <Column
          field="fecha_venta"
          header="FECHA"
          :sortable="true"
          :filter="true"
        />
        <Column field="nombre_cliente" header="CLIENTE" />
        <Column field="dias_credito" class="text-center" header="DIAS CREDITO" />
        <Column header="DEBE Bs" class="text-right">
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(
                parseFloat(slotProps.data.saldo)
              )
            }}
          </template>
        </Column>
        <Column class="text-right" header="TOTAL VENTA">
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.total_cancelar) }}
          </template>
        </Column>
        <Column class="text-right" header="i MONTO">
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(slotProps.data.monto_interes_total)
            }}
          </template>
        </Column>
        <Column class="text-right" header="TOTAL A CANCELAR">
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(slotProps.data.total_venta_interes)
            }}
          </template>
        </Column>
        <ColumnGroup type="footer">
          <Row>
            <Column
              footer="TOTALES:"
              :colspan="5"
              footerStyle="text-align:right"
            />
            <Column class="text-right" :footer="total_debe" />
            <Column class="text-right" :footer="total_ventas" />
            <Column class="text-right" :footer="total_interes" />
            <Column class="text-right" :footer="total_a_pagar" />
          </Row>
        </ColumnGroup>
        <template #empty>
          <div class="p-d-flex p-ai-center p-jc-center" style="height: 100px">
            <i class="pi pi-search" style="font-size: 2rem"></i>
            <span class="p-ml-2">No hay resultados</span>
          </div>
        </template>
        <template #loading>
          <div class="p-d-flex p-ai-center p-jc-center" style="height: 100px">
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
            <span class="p-ml-2">Cargando...</span>
          </div>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import SucursalService from "@/service/SucursalService";
import ClienteService from "@/service/ClienteService";
import VentaService from "@/service/VentaService";
export default {
  data() {
    return {
      generando_pdf: false,
      sucursales: [{ id: 0, nombre: "Todas" }],
      clienteSelected: null,
      clientes: [],
      sucursalSelected: null,
      mesSelected: null,
      meses: [
        { id: 0, name: "Seleccione" },
        { id: 1, name: "Enero" },
        { id: 2, name: "Febrero" },
        { id: 3, name: "Marzo" },
        { id: 4, name: "Abril" },
        { id: 5, name: "Mayo" },
        { id: 6, name: "Junio" },
        { id: 7, name: "Julio" },
        { id: 8, name: "Agosto" },
        { id: 9, name: "Septiembre" },
        { id: 10, name: "Octubre" },
        { id: 11, name: "Noviembre" },
        { id: 12, name: "Diciembre" },
      ],
      rango_fecha_busqueda: null,
      codigo_cliente: null,
      estadoVentasSelected: null,
      estadosVentas: [
        { id: 0, nombre: "Todos" },
        { id: 1, nombre: "Completados" },
        { id: 2, nombre: "Pendientes" },
      ],
      ventas: [],
      enviando: false,
      filters: {
        global: {
          value: null,
          matchMode: "contains",
        },
      },
      filtros: {
        sucursal: null,
        mes: null,
        rango_fecha_busqueda: null,
        codigo_cliente: null,
        estado: 0,
      },
      errorClienteSelected: false,
    };
  },
  sucursalService: null,
  clienteService: null,
  ventaService: null,
  created() {
    this.sucursalService = new SucursalService();
    this.clienteService = new ClienteService();
    this.ventaService = new VentaService();
  },

  mounted() {
    this.GetSucursales();
  },

  methods: {
    generarPDF() {
      this.generando_pdf = true;
      this.validarFiltros();
      this.ventaService
        .generarReporteVentasCreditoInteres(this.filtros)
        .then((data) => {
          this.generando_pdf = false;
          window.open(data.url, "_blank");
        })
        .catch((err) => {
          console.log(err);
          this.generando_pdf = false;
        });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    convertirFecha(fecha) {
      return new Date(fecha).toISOString().slice(0, 10);
    },
    validarFiltros() {
      this.filtros.sucursal = this.sucursalSelected
        ? this.sucursalSelected.id
        : null;
      this.filtros.codigo_cliente = this.codigo_cliente
        ? this.codigo_cliente
        : null;
      this.filtros.mes = this.mesSelected ? this.mesSelected : null;
      if (this.rango_fecha_busqueda != null) {
        this.filtros.desde = this.convertirFecha(this.rango_fecha_busqueda[0]);
        this.filtros.hasta =
          this.rango_fecha_busqueda[1] != null
            ? this.convertirFecha(this.rango_fecha_busqueda[1])
            : null;
      }
      this.filtros.estado = this.estadoVentasSelected
        ? this.estadoVentasSelected
        : 0;
    },
    buscarDatos() {
      this.validarFiltros();
      this.enviando = true;
      this.ventaService
        .buscarVentasFiltros(this.filtros)
        .then((data) => {
          this.ventas = data.ventas;
          this.enviando = false;
        })
        .catch((err) => {
          console.log(err);
          this.enviando = false;
        });
    },
    LimpiarFiltro() {
      this.sucursalSelected = null;
      this.mesSelected = null;
      this.rango_fecha_busqueda = null;
      this.codigo_cliente = null;
      this.estadoVentasSelected = null;
      this.filters.global.value = null;
      this.filters.global.matchMode = "contains";
    },
    GetSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((data) => {
          for (let sucursal of data) {
            this.sucursales.push({ ...sucursal });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    BuscarClientePorNombre() {
      this.clienteService
        .buscarXNombre({ texto: this.clienteSelected })
        .then((data) => {
          if (data.clientes.length > 0) {
            this.clientes = data.clientes;
            //show options select clientes
            this.$refs.clientes_select.show();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSelectCliente(event) {
      this.clienteSelected = event.value;
      this.codigo_cliente = this.clienteSelected.codigo;
      this.errorClienteSelected = false;
    },
    GetClienteCodigo() {
      this.clienteService
        .getClienteCodigo(this.codigo_cliente)
        .then((data) => {
          this.clienteSelected = data.cliente;
          this.clientes.push({ ...data.cliente });
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    verDetalleVenta(event) {
      let data = event.data;
      //open new tab
      window.open(
        this.$router.resolve({
          name: "venta_detalle",
          params: { id: data.id },
        }).href,
        "_blank"
      );
    },
  },

  watch: {
    clienteSelected(val) {
      if (val == null) {
        this.errorClienteSelected = true;
      } else {
        this.errorClienteSelected = false;
        this.codigo_cliente = val.id;
      }
    },
  },

  computed: {
    total_ventas() {
      let total = 0;
      if (this.ventas.length > 0) {
        for (let venta of this.ventas) {
          total += parseFloat(venta.total_cancelar);
        }
      }
      return this.convertirNumeroGermanicFormat(total);
    },
    total_interes() {
      let total = 0;
      if (this.ventas.length > 0) {
        for (let venta of this.ventas) {
          total += parseFloat(venta.monto_interes_total);
        }
      }
      return this.convertirNumeroGermanicFormat(total);
    },
    total_a_pagar() {
      let total = 0;
      if (this.ventas.length > 0) {
        for (let venta of this.ventas) {
          total += parseFloat(venta.total_venta_interes);
        }
      }
      return this.convertirNumeroGermanicFormat(total);
    },
    total_debe(){
      let total = 0;
      if (this.ventas.length > 0) {
        for (let venta of this.ventas) {
          total += parseFloat(venta.saldo);
        }
      }
      return this.convertirNumeroGermanicFormat(total);
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
.p-fondo {
  background-color: #7bffae;
}
</style>
